/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  ul {
    list-style-type: disc !important;
    margin-block-start: 1em  !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 40px !important;
  }
}

/* no style for the ul inside the navbar */
.navbar ul {
  list-style-type: none !important;
  margin-block-start: 0em  !important;
  margin-block-end: 0em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  padding-inline-start: 0px !important;
}


html, body {
    height:100%;
}

.external{
  background-image: url("./assets/img/background.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left top;
}

.internal{
  background-image: url("./assets/img/background.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left top;
}

.card{
  border-radius: 0.2rem !important;
}


/** Snackbar **/
  /* Error */
.mat-mdc-snack-bar-container.error .mdc-snackbar__surface {
  @apply bg-red-600 #{'!important'};
}

